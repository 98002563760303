body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
	border-radius: 6px;
}

.App {
  height: 100%;
}

#root {
  height: 100%;
}

.page {
  height: 100%;
}

/* img {
	box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 4px #ffd700, 0 0 5px #f9b237;
	transition: all 0.5s;
}  
img:hover {
	cursor: pointer;
	box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 7px #fff, 0 0 10px #ffd700, 0 0 15px #ffd700, 0 0 20px #ffd700, 0 0 30px #f9b237;
} */