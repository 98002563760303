@keyframes rotate {
	0% {
		background-position: 0% 0%;
	}

	20% {
		background-position: 100% 0%;
	}

	40% {
		background-position: 0% 0%;
	}
}

@keyframes rotate2 {
	0% {
		background-position: 0% 0%;
	}
	10% {
		background-position: 20% 20%;
	}


	70% {
		background-position: 100% 100%;
	}

	100% {
		background-position: 0% 0%;
	}
}



.selecting {
	--background: linear-gradient(60deg, rgb(68, 11, 223), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgb(0, 60, 255), rgba(0, 0, 0, 0), rgb(0, 0, 0, 0));
	--animation: rotate 8s both infinite;
}

.selected {
	--light: #EEE;
	--gold: #FFD700;
	--goldenrod: #DAA520;
	--t: rgba(0, 0, 0, 0);

	--background: linear-gradient(60deg, var(--light), var(--gold), var(--gold), var(--goldenrod), var(--gold), var(--goldenrod));
	--animation: rotate2 8s forwards infinite;
}

.border_animate {
	--border-width: 2px;

	border-radius: var(--border-width);

	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
}

.border_animate::after {
	position: absolute;
	content: "";
	top: calc(-1 * var(--border-width));
	left: calc(-1 * var(--border-width));
	z-index: 0;
	width: calc(100% + var(--border-width) * 2);
	height: calc(100% + var(--border-width) * 2);
	background: var(--background);
	background-size: 300% 300%;
	background-position: 0% 0%;
	
	border-radius: 9px;
	animation: var(--animation);
	animation-timing-function: ease-in-out;
	animation-delay: 5s;
}

/* .border_animate::before {
	flex: 1;
	animation: rotate 5s linear infinite;
	position: absolute;
	width:400px;
	height:100%;
	box-shadow: 1px;
	border-radius: 8px;
	content: '';
	z-index: -1;
} */

/* .border_animate::after {
	content: '';
	position: absolute;
	background: #0e1538;
	inset: 5px;
	border-radius:15px;
	z-index: -2;
} */