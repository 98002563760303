@font-face {
	font-family: 'Under Authority';
	src: url("../fonts/UnderAuthority.ttf");
}

@font-face {
	font-family: 'Bebas';
	src: url("../fonts/BebasNeue-Regular.woff2");
}

.transformButton {
	width: 165px;
	will-change: transform, opacity;
}

body {
	height: 100%;
}

html {
	height: 100%;
}

.header {
	text-align: center;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}